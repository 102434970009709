import { useContext, useEffect, useReducer, useState } from "react";
import GameBoard from "../../components/game-board/GameBoard";
import { AuthContext } from "../../context/auth.context";
import { useNavigate } from "react-router-dom";
import { DisplayContext } from "../../context/display.context";
import { WorkerContext } from "../../context/worker/worker.context";
import { UserContext } from "../../context/user.context";
//import ApiClient from "../../services/api-client.service";
import { Link } from "react-router-dom";
// import crypto from "crypto";

export default function Game({ selectedGame, onSetPlayers }) {
  const [game, setGame] = useState(selectedGame);
  const [players, setPlayers] = useState([]); //useReducer(playersReducer, []);
  const devicdeWidth = useContext(DisplayContext);
  const [auth] = useContext(AuthContext);
  const [user] = useContext(UserContext);
  const navigate = useNavigate();
  //const apiClient = new ApiClient();

  const { worker, workerMessage } = useContext(WorkerContext);

  useEffect(() => {
    if (!auth) {
      navigate("/auth/signin");
    }

    if (workerMessage && "as" in workerMessage) {
      console.log(workerMessage);
      switch (workerMessage.as) {
        case "game-room":
          setPlayers(workerMessage.players);
          break;
        case "game-play":
          if (workerMessage.type === "complete") {
            setGame((g) => ({ ...g, status: workerMessage.type }));
          }
          break;
      }
    }
  }, [workerMessage, auth]);

  function handlePlay(data, type) {
    worker.postMessage({
      action: "send",
      as: "game-play",
      payload: {
        ...data,
        type,
        gameSlot: game.slot,
        game: game.gameID,
      },
    });
  }

  function handleAdClick(data) {
    console.log("ad clicked");
    worker.postMessage({
      action: "send",
      as: "event",
      type: "click-ad",
      payload: {
        gameSlot: 0,
        game: game.gameID,
      },
    });
  }

  function handleMoves(move) {
    if (game.status === "pending") handlePlay(move, "game-interaction");
  }

  function handleSolved(stats) {
    if (game.status === "pending") {
      const gameStats = { ...stats, code: crypto.randomUUID() };
      const wins = JSON.parse(atob(localStorage.getItem("wins") || btoa("[]")));
      wins.push(gameStats);
      localStorage.setItem("wins", btoa(JSON.stringify(wins)));
      handlePlay(gameStats, "complete");
    }
  }

  function gameStatus() {
    return (
      <h1
        className={`badge badge-lg bg-${
          game.status == "pending" ? "success" : "danger"
        }`}
        style={{ fontSize: "18px" }}
      >
        <span>{game.status == "pending" ? "Open" : "Closed"}</span>
      </h1>
    );
  }

  return (
    <div
      className={`d-flex ${
        devicdeWidth > 968 ? "" : "flex-column align-items-center"
      }`}
    >
      <div
        className={`position-relative ${devicdeWidth > 480 ? " p-4 pt-0" : ""}`}
      >
        <GameBoard
          rows={4}
          onMove={handleMoves}
          onSolved={handleSolved}
          image={`${process.env.REACT_APP_PRODUCTION_API_BASE_URL}/static/${game.image}`}
          width={
            devicdeWidth > 480
              ? Math.floor(window.innerHeight * 0.6)
              : Math.floor(devicdeWidth * 0.8)
          }
        />
      </div>
      <div
        className={`d-flex flex-column justify-content-between ${
          devicdeWidth > 480 ? " p-4 pt-0" : ""
        }`}
      >
        <div className="d-block">
          <span className="d-block">Game Status</span>
          {gameStatus()}{" "}
          <span className="badge bg-primary">
            {"No longer available if closed. Keep watch"}
          </span>
        </div>
        <div className="d-flex justify-content-betwee w-100">
          <div className={`pt-3 pe-3`}>
            <span>Game Category</span>
            <h5>{game.parent_category.categoryName}</h5>
          </div>
          <div className="pt-3 ps-3">
            <span>Earn</span>
            <h5>{game.prize}$</h5>
          </div>
        </div>
        <div className=" mb-auto">
          <h4>How to play</h4>
          <p>
            Click / Tap / Drag and Drop the boxes surrounding the empty box
            towards it until you fix the image to look like the one below. When
            completed the empty box should be at the bottom right corner. Closed
            status means game NO longer available.
          </p>
        </div>
        <div>
          <img
            alt=""
            src={`${process.env.REACT_APP_PRODUCTION_API_BASE_URL}/static/${game.image}`}
            width={250}
            height={250}
            style={{ objectFit: "cover" }}
          />
        </div>
      </div>
      <div>
        {/*game.gif && (
          <Link to={`${game.giflink}`} onClick={handleAdClick} target="_blank">
            <img
              src={`${process.env.REACT_APP_PRODUCTION_API_BASE_URL}/static/${game.gif}`}
              width={250}
              height={250}
            />
          </Link>
        )*/}
        {/* players.length > 0 && <>  width={"100%"}
                <h4>Other Players</h4>
                <p className="m-0">You are competing with</p>
                <ul className="list-group list-group-flush">
                    {players.map(p => <li key={`plrs${p.username}`} className="list-group-item">
                        {user && p.username === user.username ? "Yourself" : p.username}
                    </li>)}
                </ul></> */}
      </div>
    </div>
  );
}
